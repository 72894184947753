.service-links {
  box-sizing: border-box;
  text-align: center;
  width: 50%;
}

.service-body {
  padding: 1em;
}

/*# sourceMappingURL=index.d63e1e2c.css.map */
